import * as React from "react"
import { Link } from "gatsby"
import { Layout } from "../components/layout"
import styled from "@emotion/styled"
import { std } from "../components/common"

const NotFoundBody = styled.div`
  display: flex;
  flex-direction: column; 
  justify-content: center;
  align-items: center;
  height: calc(100vh - ${std.nav_h});
`

const NotFoundPage = () => {
  return (
    <Layout>
      <NotFoundBody>
        <h1 style={{ fontSize: '10rem', color: std.emph_c, margin: '0' }}>404</h1>
        <p style={{ fontSize: '1.25rem', margin: '0.3rem 0' }}>
          页面洁白如雪 正似甘甜蜜饴
        </p>
        <Link to="/"><button className="button" style={{ margin: '0.3rem 0' }}>回到首页</button></Link>
      </NotFoundBody>
    </Layout >
  )
}

export default NotFoundPage

export const Head = () => <title>页面不存在</title>
